app-group-form .container {
  padding: 15px; }

app-group-form .fm-container {
  background-color: #D7D7D7;
  padding-bottom: 20%; }

app-group-form .flex-item {
  display: table-row; }

app-group-form .flex-item-cell-lbl {
  display: table-cell;
  text-align: right;
  padding: 5px; }

app-group-form .flex-item-cell {
  display: table-cell;
  padding: 5px;
  width: 80%; }

app-group-form .header-title {
  padding: 5px;
  text-align: center; }

app-group-form .buttons {
  margin: 15px 0 15px 0; }

app-group-form .buttons button {
  margin-right: 15px; }

app-group-form h4 {
  margin: 10px 0 0 0; }

app-group-form label {
  display: block;
  width: 8em;
  margin: .5em 0;
  color: #607D8B;
  font-weight: bold; }

app-group-form input[type="text"], app-group-form textarea {
  height: 2em;
  font-size: 1em;
  padding-left: .4em;
  width: 100%; }

app-group-form textarea {
  flex: 1;
  height: 100px; }

app-group-form button {
  font-family: Arial;
  background-color: #eee;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer; }

app-group-form button:hover {
  background-color: #cfd8dc; }

app-group-form button:disabled {
  background-color: #eee;
  color: #ccc;
  cursor: auto; }
