app-group-users .delete-button {
  cursor: pointer;
  color: blue !important;
  text-decoration: underline !important; }

app-group-users .button-settings {
  margin: 10px 0 10px 0; }

app-group-users .search {
  margin: 10px 0 10px 0;
  width: 400px; }
