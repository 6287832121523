app-user-manage h4 {
  margin: 10px 0 0 0; }

app-user-manage .first-name {
  clear: left; }

app-user-manage .first-name, app-user-manage .last-name {
  float: left; }

app-user-manage .last-name {
  margin: 0 0 0 15px; }

app-user-manage .user-grid-container {
  clear: left;
  margin: 20px 0 0 0; }

app-user-manage button {
  float: left; }

app-user-manage .user-data-header, app-user-manage .user-data-cell {
  padding: 10px 0 0 10px; }

app-user-manage label {
  display: block;
  width: 8em;
  margin: .5em 0;
  color: #607D8B;
  font-weight: bold; }

app-user-manage input[type="text"], app-user-manage textarea {
  height: 2em;
  font-size: 1em;
  padding-left: .4em;
  width: 100%; }

app-user-manage textarea {
  flex: 1;
  height: 100px; }

app-user-manage button {
  font-family: Arial;
  background-color: #eee;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer; }

app-user-manage button:hover {
  background-color: #cfd8dc; }

app-user-manage button:disabled {
  background-color: #eee;
  color: #ccc;
  cursor: auto; }

app-user-manage .fm-container {
  background-color: #D7D7D7;
  padding-bottom: 30%; }

app-user-manage .flex-item {
  display: block;
  clear: left; }

app-user-manage .flex-item-cell-lbl {
  float: left;
  text-align: right;
  padding: 5px; }

app-user-manage .flex-item-cell {
  float: left;
  padding: 5px; }

app-user-manage .flex-item-cell1 {
  width: 77%; }

app-user-manage .header-title {
  padding: 5px;
  text-align: center; }
