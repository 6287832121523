app-group-list {
  /**
    * @todo revisit the height of the card
    */ }
  app-group-list .group-card {
    width: 225px;
    float: left;
    border: 1px solid black;
    height: 210px;
    text-align: center;
    padding: 10px;
    margin: 10 0 25px 15px;
    display: block; }
  app-group-list .group-card.next-line {
    clear: left; }
  app-group-list .group-card h3 {
    padding: 0;
    margin: 0; }
  app-group-list .card-info {
    width: 225px;
    text-align: center;
    height: 210px;
    margin: 0 0 25px 15px;
    border: 1px solid black;
    float: left; }
  app-group-list .button-circle {
    border-radius: 50%; }
