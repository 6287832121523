app-find-workspace .result {
  height: 100px;
  width: 100%;
  background-color: #f3f3f3;
  padding: 1rem;
  margin-bottom: 0.75rem;
  cursor: pointer; }

app-find-workspace .description, app-find-workspace .id {
  color: #777777; }

app-find-workspace .title, app-find-workspace .id {
  display: block; }

app-find-workspace .title {
  font-weight: 600;
  font-size: 1.2rem; }

app-find-workspace .email {
  color: #472890; }
