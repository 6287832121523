app-page-manage .container {
  padding: 15px; }

app-page-manage .buttons {
  margin: 15px 0 15px 0; }

app-page-manage .buttons button {
  margin-right: 15px; }

app-page-manage h4 {
  margin: 10px 0 0 0; }

app-page-manage label {
  display: block;
  margin: .5em 0;
  color: #607D8B;
  font-weight: bold; }

app-page-manage td.checkbox {
  padding: 10px; }

app-page-manage button {
  font-family: Arial;
  background-color: #eee;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer; }

app-page-manage button:hover {
  background-color: #cfd8dc; }

app-page-manage button:disabled {
  background-color: #eee;
  color: #ccc;
  cursor: auto; }

app-page-manage .title-input {
  width: 500px; }

app-page-manage .editor-container, app-page-manage .group-container {
  margin: 20px 0 0 0; }
