app-group-manage .container {
  padding: 15px; }

app-group-manage .buttons {
  margin: 15px 0 15px 0;
  clear: left;
  float: left; }

app-group-manage .buttons button {
  margin-right: 15px; }
