app-facility .create-tenant-welcome {
  background-color: #472890;
  min-height: 150px; }

app-facility .welcome-logo-text {
  text-align: center;
  padding: 30px 0 30px 0; }

app-facility .welcome-logo, app-facility .welcome-text {
  display: inline; }

app-facility .welcome-logo img {
  width: 10%; }

app-facility .welcome-text {
  color: #ffffff;
  font-size: 2em;
  font-weight: bold; }

app-facility .content-area {
  margin: 20px auto 0 auto;
  width: 80%; }

@media (min-width: 992px) {
  app-facility .create-tenant-welcome {
    min-height: 800px;
    position: relative; }
  app-facility .content-area {
    margin: 20px 0 0 20px; }
  app-facility .welcome-logo-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  app-facility .welcome-logo {
    text-align: center; }
  app-facility .welcome-logo img {
    width: 100%; }
  app-facility .welcome-text {
    color: #ffffff;
    margin: 10px 0 0 0;
    text-align: center;
    font-size: 3em;
    width: 100%; } }

app-facility h1 {
  margin: 0 0 40px 0; }
