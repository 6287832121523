app-facility-home {
  /* Avoid css leak. Remove when ng-core styles have been removed */ }
  app-facility-home .menu-wrapper {
    width: 382px;
    float: left;
    min-height: 100%;
    height: 100%;
    display: block; }
  app-facility-home .menu-wrapper.extra {
    width: calc(382px + 80px); }
  app-facility-home .menu-wrapper.closed {
    width: 80px !important; }
  app-facility-home .top-nav-wrapper {
    float: left;
    width: calc(100% - 382px); }
  app-facility-home .top-nav-wrapper.extra {
    width: calc(100% - 382px - 80px); }
  app-facility-home .top-nav-wrapper.closed {
    width: calc(100% - 82px) !important; }
  app-facility-home .menu-item {
    cursor: pointer;
    margin: 10px 0 15px 0;
    font-size: 1.5em; }
  app-facility-home .menu-item.selected {
    background-color: blue; }
  app-facility-home .content, app-facility-home .top-nav-wrapper, app-facility-home .menu-wrapper {
    transition: 0.3s width; }
  app-facility-home .storage-wrapper {
    margin-right: 15px;
    margin-top: 15px; }
  app-facility-home .storage-wrapper fm-item .title {
    width: 150px; }
  app-facility-home .content {
    float: left;
    margin-left: 15px;
    width: calc(100% - 382px - 15px);
    height: 600px;
    overflow: auto; }
  app-facility-home .content.extra {
    width: calc(100% - 382px - 80px - 15px); }
  app-facility-home .content.closed {
    width: calc(100% - 82px - 15px) !important; }
  app-facility-home .item-name {
    font-size: 0.85em;
    font-weight: bold; }
  app-facility-home sc-left-nav .top, app-facility-home sc-left-nav .bottom {
    margin-top: 0;
    margin-bottom: 0; }
  app-facility-home sc-left-nav .tenant-item .tenant-name {
    height: 1.5em !important; }
